<template>
  <div>
    <a-select
      :value="selectedValue"
      showSearch
      :filterOption="filterOption"
      style="width: 100%"
      allowClear
      @change="changeValue"
    >
      <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id" :item="item">
        <div v-if="item.type === 'purchase'">{{ item.purchase_order_item.number }}</div>
        <div v-if="item.type === 'production_stock_in'">{{ item.production_order_item.number }}</div>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { stockInOrderOption } from "@/api/option";

export default {
  props: ["value", "type"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      selectedValue: undefined,
      dataItems: [],
    };
  },
  methods: {
    changeValue(value) {
      this.selectedValue = value;
      this.$emit("change", value);
    },
    filterOption(input, option) {
      const item = option.data.attrs.item;
      if (item.type === "purchase" && item.purchase_order_item.number.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
        return true;
      }

      if (
        item.type === "production_stock_in" &&
        item.production_order_item.number.toLowerCase().indexOf(input.toLowerCase()) >= 0
      ) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.selectedValue = this.value;
    }
    stockInOrderOption({ page_size: 999999, type: this.type }).then((data) => (this.dataItems = data.results));
  },
};
</script>

<style scoped></style>
